
import View from '@/utils/crud/view'
import ViewInterface from '@/utils/crud/viewInterface'
import ViewCompenent from '@/utils/crud/view.vue'
import router from '@/router'
export default {
  components:{
    ViewCompenent
  },
  computed:{
    
   d(){
     const details : ViewInterface = {
       title: 'projects',
       table: 'projects',
       viewable:[
            "UserName",
            "CategoryName",
            "City",
            "Title",
            "Logo",
            "Img",
            "Fund",
            "Status",
            "Breif",
            "Imgs",
            "Location",
            "Phone",
            "File",
            "Email",
            "Featured",
            "Website",
            "Instagram",
            "Twitter"
      ],
       id: parseInt(router.currentRoute.params.id)
     }
     const d = new View(details)
     return d
   }
  },
}
